// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_GuF1n{background-color:#f2eeea;width:100%;transition:opacity .3s}.anchor_2s9cx:before{content:\"\";display:block;margin-top:-90px;height:90px;visibility:hidden;pointer-events:none}@media only screen and (min-width:800px){.anchor_2s9cx:before{margin-top:-100px;height:100px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_GuF1n",
	"anchor": "anchor_2s9cx"
};
module.exports = ___CSS_LOADER_EXPORT___;
