<template>
  <div :class="$style.testimonialsContainer">
    <TransitionOnScroll :translateY="30">
      <BeforeHeading :class="$style.testimonialsBeforeTitle">{{ $t('beforeTitle') }}</BeforeHeading>
    </TransitionOnScroll>
    <TransitionOnScroll :translateY="50">
      <Heading :class="$style.testimonialsTitle" :tag="HEADINGS.h2">{{ $t('title') }}</Heading>
    </TransitionOnScroll>

    <TransitionOnScroll :scale="0.95">
      <Carousel
        :options="carouselOptions"
        :isAutoplaying="isAutoplaying"
        :loopKey="loopKey"
        @active-index="updateDelayedActiveIndex"
        @click.native="updateReadMore"
      >
        <HomeTestimonialsSlide
          v-for="(testimonial, index) in testimonials"
          :key="`testimonial-${index}`"
          :text="getText(testimonial)"
          :translatedFrom="getTranslatedFrom(testimonial)"
          :firstName="testimonial.firstName"
          :gender="testimonial.gender"
          :location="testimonial.location"
          :imgSrc="testimonial.imgSrc"
          :readMoreText="$t('readMore')"
          :isReadingMore="isReadingMore[index]"
          :data-slide-index="index"
          @update="loopKey++"
        />
      </Carousel>
    </TransitionOnScroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { randomBetween } from '@/helpers/math'
import BeforeHeading from '@/components/Heading/BeforeHeading.vue'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import Carousel from '@/components/Carousel/Carousel.vue'
import TransitionOnScroll from '@/components/TransitionOnScroll.vue'
import HomeTestimonialsSlide from './HomeTestimonialsSlide.vue'
import { testimonials } from './testimonials'

export default {
  name: 'HomeTestimonials',
  components: {
    BeforeHeading,
    Carousel,
    Heading,
    HomeTestimonialsSlide,
    TransitionOnScroll,
  },

  data() {
    return {
      delayedActiveIndex: 0,
      HEADINGS,
      isAutoplaying: true,
      isReadingMore: {},
      loopKey: 1, // to update the loop making sure the duplicated slides are up-to-date
      testimonials,
    }
  },

  computed: {
    ...mapGetters({
      language: 'page/language',
    }),

    carouselOptions() {
      return {
        initialSlide: randomBetween(0, testimonials.length - 1),
        slidesPerView: 1.1,

        breakpoints: {
          [parseInt(this.$style.breakpointSmall)]: {
            slidesPerView: 1.2,
          },
          [parseInt(this.$style.breakpointMedium)]: {
            slidesPerView: 1.5,
          },
          [parseInt(this.$style.breakpointLarge)]: {
            slidesPerView: 1.7,
          },
          [parseInt(this.$style.breakpointLarger)]: {
            slidesPerView: 2,
          },
          [parseInt(this.$style.breakpointFull)]: {
            slidesPerView: 2.5,
          },
          [parseInt(this.$style.breakpointHigh)]: {
            slidesPerView: 3,
          },
          1700: {
            slidesPerView: 3.5,
          },
        },
      }
    },
  },

  methods: {
    getText(testimonial) {
      return (
        testimonial.originalText[this.language] ||
        testimonial.translatedText[this.language] ||
        Object.values(testimonial.originalText)[0]
      )
    },

    getTranslatedFrom(testimonial) {
      return !testimonial.originalText[this.language] && testimonial.translatedText[this.language]
        ? Object.keys(testimonial.originalText)[0]
        : undefined
    },

    updateDelayedActiveIndex(index) {
      setTimeout(() => (this.delayedActiveIndex = index), 50)
    },

    // This is needed for the duplicated slides that can't have a click event on the
    // read-more text (duplicated slides because of the loop in the carousel).
    updateReadMore(event) {
      this.isAutoplaying = false
      if (!event.target.matches('[data-read-more]')) return

      const slideElement = event.target.closest('[data-slide-index]')
      if (slideElement) {
        const slideIndex = Number(slideElement.dataset.slideIndex)
        if (slideIndex === this.delayedActiveIndex) this.$set(this.isReadingMore, slideIndex, true)
      }
    },
  },
}
</script>

<style lang="scss" module>
:export {
  breakpointTiny: $mq-tiny;
  breakpointSmall: $mq-small;
  breakpointMedium: $mq-medium;
  breakpointLarge: $mq-large;
  breakpointLarger: $mq-larger;
  breakpointFull: $mq-full;
  breakpointHigh: $mq-high;
  breakpointCinema: $mq-cinema;
}

.testimonialsContainer {
  background-color: $color-white;
  padding: 60px 0 100px;
  text-align: center;

  @include min-screen($mq-medium) {
    padding: 80px 0 120px;
  }

  @include min-screen($mq-larger) {
    padding: 80px 0 150px;
  }
}
</style>

<i18n src="@/locales/home-testimonials.json" />
