<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 416 371"
  >
    <defs>
      <linearGradient
        :id="`grad_${hash}`"
        x1="1806.4698"
        y1="-596.1253"
        x2="2226.374"
        y2="-160.3758"
        gradientTransform="translate(-1611.2993 2.9739) rotate(23.2091)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fbc244" />
        <stop offset="0.9964" stop-color="#ed6f57" />
      </linearGradient>
      <linearGradient
        :id="`grad-3_${hash}`"
        x1="990.8165"
        y1="-196.6982"
        x2="1248.8032"
        y2="-59.6691"
        gradientTransform="translate(-872.7484 91.9327) rotate(14.0752)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" />
        <stop offset="1" stop-color="#2a3056" />
      </linearGradient>
      <linearGradient
        :id="`grad-2_${hash}`"
        x1="1144.1668"
        y1="-187.4687"
        x2="1564.0734"
        y2="248.2833"
        gradientTransform="translate(-872.7484 91.9327) rotate(14.0752)"
        :xlink:href="`#grad_${hash}`"
      />
      <linearGradient
        :id="`grad-3-2_${hash}`"
        x1="969.9174"
        y1="-157.6696"
        x2="1162.237"
        y2="-176.4187"
        :xlink:href="`#grad-3_${hash}`"
      />
      <linearGradient
        :id="`grad-3-3_${hash}`"
        x1="-2138.3402"
        y1="33.5727"
        x2="-2177.8804"
        y2="238.0906"
        gradientTransform="matrix(-1, 0, 0, 1, -1975.6362, 0)"
        :xlink:href="`#grad-3_${hash}`"
      />
    </defs>
    <g :class="$style['cls-1']">
      <path
        :class="$style['cls-2']"
        d="M92.2244,29.3771C78.37,34.5183,66.6061,45.3429,58.6022,59.5906,38.1791,95.9458-15.1243,202.5048,10.0877,284.7866c30.882,100.7866,291.9673,98.0918,360.0094,55.198,17.2871-10.8978,88.02-84.4151-2.7648-145.5242-83.2129-56.0126-117.604-14.8953-155.9744-36.9633S145.2733,46.8654,131.117,34.28C121.9744,26.1517,107.7517,23.6153,92.2244,29.3771Z"
      />
      <path
        d="M329.5675,307.0964c.1122-3.3315,6.9263-6.9829,10.8591-5.2828a10.3151,10.3151,0,0,1,2.7713,2.268,31.865,31.865,0,0,1,4.35,5.2359c4.5568,6.9756,15.0621,13.55,13.8347,15.9592-.8839,1.7344-7.5827.7847-12.7262-1.818-4.1355-2.0926-4.0985-3.6512-10.9082-9.09C331.7258,309.558,329.5026,309.0245,329.5675,307.0964Z"
      />
      <path
        :class="$style['cls-3']"
        d="M328.3138,296.9073l4.9888,8.2079a4.5343,4.5343,0,0,0,5.0273,2.03,2.9452,2.9452,0,0,0,1.7529-4.4034l-7.3479-11.821Z"
      />
      <path
        :class="$style['cls-4']"
        d="M140.1126,173.4561c-15.57,6.8139-1.2262,49.885,43.0066,51.415,53.6021,1.8538,114.2716,27.88,145.5965,75.6314,3.1395-1.4025,10.2889-2.899,8.4037-6.5834-25.9164-50.6507-54.2241-83.9837-81.6006-99.196C214.1306,171.7244,184.445,154.0549,140.1126,173.4561Z"
      />
      <g :class="$style['cls-5']">
        <path
          d="M278.3307,233.3921c5.827,6.4192,10.0254-1.5411,15.4709-5.9276-12.7414-14.726-25.6109-25.7-38.2828-32.7414-41.3879-22.9987-71.0738-40.6682-115.406-21.267-15.57,6.8138-1.2264,49.885,43.0064,51.415a198.7609,198.7609,0,0,1,48.9069,8.1022C247.6369,228.9125,264.2393,213.5054,278.3307,233.3921Z"
        />
      </g>
      <path
        d="M121.3629,207.6465l-3.2739-1.1828s-10.87-19.0581-12.52-23.8649c-2.0135-5.8653,41.4467-5.4213,59.3347-17.19,18.7963-12.3663-3.4256,20.6671-3.4256,20.6671Z"
      />
      <path
        :class="$style['cls-6']"
        d="M154.186,53.1125s13.7406,6.5213,20.641,39.4427c5.1879,24.7526,25.3868,50.2978,25.3868,50.2978l-2.5965,17.3609L175.09,157.2l-6.04-7.4454L162.2654,98.85Z"
      />
      <path
        :class="$style['cls-7']"
        d="M154.186,53.1125s13.7406,6.5213,20.641,39.4427c5.1879,24.7526,25.3868,50.2978,25.3868,50.2978l-2.5965,17.3609L175.09,157.2l-6.04-7.4454L162.2654,98.85Z"
      />
      <path
        :class="$style['cls-6']"
        d="M126.8982,42.5052S90.1054,35.6513,76.4337,76.1539c-12.6168,37.3776-3.6671,56.2437,19.6847,98.1443,2.3483,4.2131,7.7759,5.9344,8.0343,6.5151q2.0844,4.6863,4.1491,9.7194c38.85-19.1486,75.5095-18.1857,75.5095-18.1857s-10.6995-74.1146-17.5994-99.7444C159.098,46.1774,136.6814,43.2013,126.8982,42.5052Z"
      />
      <g :class="$style['cls-8']">
        <path
          :class="$style['cls-6']"
          d="M155.609,156.0489c-17.481,3.2609-38.3782-4.3566-43.0322-16.7224-3.505-9.3124-3.5754-12.3911-7.0086-26.3591-2.7615-11.2366-5.08-13.6326-15.1217-25.5381A66.3515,66.3515,0,0,1,79.4361,68.74a66.4631,66.4631,0,0,0-3.0021,7.4137c-12.617,37.3776-3.6674,56.2437,19.6844,98.1443,2.3483,4.2131,7.7759,5.9344,8.0343,6.5151q2.0844,4.6863,4.1491,9.7194c38.85-19.1486,75.5095-18.1857,75.5095-18.1857s-1.9521-11.7424-4.2762-28.12C171.9528,150.956,166.8236,153.9571,155.609,156.0489Z"
        />
      </g>
      <path
        :class="$style['cls-9']"
        d="M130.8159,149.5083s6.6122,2.4363,11.9867,2.0269,5.1218-.9746,10.8282.0674c5.5469,1.0125,17.0527,1.176,12.82-6.1169-4.7111-8.1172-16.5909-10.0244-21.1172-7.061-4.3632,2.8565-10.5852,3.5766-10.5852,3.5766Z"
      />
      <path
        :class="$style['cls-6']"
        d="M94.9833,54.74S74.5921,50.7977,66,117.7614c-4.4567,34.7308,26.4282,39.43,66.2535,37.1162,3.9993-4.2686,4.2833-15.9124,4.2833-15.9124s-49.9029,4.2119-42.6318-22.6991C102.0569,86.0943,107.4536,55.2314,94.9833,54.74Z"
      />
      <path
        :class="$style['cls-9']"
        d="M127.7108,24.82s-.9821,15.2044-2.5934,17.867,1.5208,7.1043,8.65,9.7176,3.8121-14.73,3.8121-14.73Z"
      />
      <path
        d="M148.8159,28.75c8.7241-3.165,11.6955-23.81,7.38-26.0744-2.4783-1.3-4.734,4.8587-13.9236,7.2227-7.1565,1.8407-11.7111-.3715-14.7646,3.2092a10.7807,10.7807,0,0,0-2.27,6.7178,27.133,27.133,0,0,0,1.1343,6.0218c.7295,2.8261.6529,2.7108.89,3.5413a37.7837,37.7837,0,0,0,4.7427,10.0266,26.3611,26.3611,0,0,1,2.7065-3.6816,23.25,23.25,0,0,1,4.4432-3.9205C143.16,29.2278,146.3142,29.6579,148.8159,28.75Z"
      />
      <path
        :class="$style['cls-9']"
        d="M127.9219,34.4814s1.8916,7.84,10.0148,9.877c7.2732,1.8241,13.447-13.8174,9.9849-18.669-1.1547-1.6187-4.0442-3.0642-6.7391-3.1657-1.5916-.06-2.2807.3884-4.88.24-2.397-.1367-2.9433-.5827-3.7915-.235-1.0617.4351-2.2269,1.9624-2.3318,7.081-.9173-1.8129-2.3791-2.8651-3.71-2.69a2.5553,2.5553,0,0,0-1.2458.62C123.642,29.0446,124.56,31.91,127.9219,34.4814Z"
      />
      <path
        :class="$style['cls-10']"
        d="M132.8349,159.5132a.895.895,0,0,0-.8871.3655c-.0263.0412-.41,1.6863-.4287,1.7329a.8452.8452,0,0,0,.2893,1.01l27.01,19.3213,63.2509-4.2052L194.6866,171.31Z"
      />
      <path
        :class="$style['cls-11']"
        d="M132.8907,159.2917a.8453.8453,0,0,0-.6287,1.5217l27.0105,19.3224,63.2527-4.2045-27.3833-6.4284Z"
      />
      <path
        :class="$style['cls-10']"
        d="M158.0788,179.2738l23.0861-57.2757a2.1334,2.1334,0,0,1,2.3009-1.3114l70.7259,10.8066a2.1334,2.1334,0,0,1,1.68,2.8466l-19.8658,53.9077a2.1334,2.1334,0,0,1-2.2654,1.3794l-74.11-9.2234Z"
      />
      <path
        :class="$style['cls-11']"
        d="M158.8185,181.9432l23.6365-58.5244a2.1334,2.1334,0,0,1,2.3-1.31l70.7271,10.8065a2.1334,2.1334,0,0,1,1.68,2.8467l-19.8688,53.9155a2.1333,2.1333,0,0,1-2.2551,1.3806Z"
      />
      <path
        :class="$style['cls-12']"
        d="M158.8185,181.9432l76.22,9.1148a2.1335,2.1335,0,0,0,2.2552-1.38l5.9922-16.26c-25.2689,5.3752-52.597-1.583-79.1537-4.63Z"
      />
      <path
        d="M284.4747,208.2316c.0985-3.7241-.8785-8.1833,7.7467-8.4777,6.2608-.2138,16.2412,2.2425,25.2715,4.6026,12.6546,3.3073,13.1579,11.6649-6.5423,10.6377C298.05,214.3216,284.3349,213.5273,284.4747,208.2316Z"
      />
      <path
        :class="$style['cls-13']"
        d="M295.833,206.0816a3.4729,3.4729,0,0,1-3.8386,2.784c-3.8511-.6413-2.5588-2.319-2.07-4.2658s-.5816-3.7447,3.8384-2.7838C295.4063,202.1736,296.321,204.135,295.833,206.0816Z"
      />
      <path
        :class="$style['cls-14']"
        d="M261.5045,183.118l3.8188.5794s6.875,2.9077,24.6943,14.4787c3.96,2.0182,5.7931,16.0231-6.6064,12.6208s-30.1957.9944-30.1957.9944Z"
      />
      <path
        :class="$style['cls-15']"
        d="M221.3885,182.503c-24.4852,3.0057-59.3139-1.7961-66.4167-2.8907-9.0939-1.4021-38.2993,11.7016-38.6155,19.9692,0,0-2.6778,12.7138,24.9,22.8618l67.7714,4.9171c23.663-5.6481,59.5769-18.77,62.4382-28.4919C277.4064,178.6857,250.3975,178.9416,221.3885,182.503Z"
      />
      <path
        :class="$style['cls-5']"
        d="M170.8072,195.0053c17.589,3.6636,25.29-7.3513,47.6126-4.168,14.4656,2.0631,18.0318,7.6585,26.5223,5.1872,6.7024-1.9512,11.5708-7.5131,15.1515-14.095-9.6486-2.1809-23.9017-1.2441-38.7051.5732-23.7816,2.92-57.32-1.5264-65.7367-2.7873-.2488-.0375-5.0108-3.66-5.0108-3.66s-4.7217,1.0177-8.16,1.9126C151.7534,187.4951,164.3832,193.6671,170.8072,195.0053Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPricingBubble',

  computed: {
    hash() {
      return this.$style.rawHash.replace(/[^[A-Za-z0-9]/g, '')
    },
  },
}
</script>

<style lang="scss" module>
$hash: '6kLN13d';

:export {
  rawHash: $hash;
}

.cls-1 {
  isolation: isolate;
}

.cls-2 {
  fill: #93ccde;
}

.cls-3 {
  fill: url(#grad_#{$hash});
}

.cls-4 {
  fill: url(#grad-3_#{$hash});
}

.cls-5,
.cls-7,
.cls-8 {
  mix-blend-mode: multiply;
}

.cls-5 {
  opacity: 0.3;
}

.cls-6,
.cls-7 {
  fill: #f5ba93;
}

.cls-7,
.cls-8 {
  opacity: 0.75;
}

.cls-9 {
  fill: #c59b6c;
}

.cls-10 {
  fill: #232c59;
}

.cls-11 {
  fill: #cccbcb;
}

.cls-12 {
  fill: #b3b3b2;
}

.cls-13 {
  fill: url(#grad-2_#{$hash});
}

.cls-14 {
  fill: url(#grad-3-2_#{$hash});
}

.cls-15 {
  fill: url(#grad-3-3_#{$hash});
}
</style>
