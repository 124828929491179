// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".beforeHeading_hoMrZ{font-size:15px;font-weight:700;letter-spacing:.1rem;text-transform:uppercase}@media only screen and (min-width:600px){.beforeHeading_hoMrZ{font-size:17px}}@media only screen and (min-width:1150px){.beforeHeading_hoMrZ{font-size:18px}}.yellow_3MIC3{color:#ffb52e}.green_Wbbe8{color:#6c9}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"beforeHeading": "beforeHeading_hoMrZ",
	"yellow": "yellow_3MIC3",
	"green": "green_Wbbe8"
};
module.exports = ___CSS_LOADER_EXPORT___;
