// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slant_9AZT9{position:relative;overflow:hidden}.iconContainer_3gMh8{position:absolute;bottom:-1px;width:100%}.icon_Unm3L{vertical-align:top}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slant": "slant_9AZT9",
	"iconContainer": "iconContainer_3gMh8",
	"icon": "icon_Unm3L"
};
module.exports = ___CSS_LOADER_EXPORT___;
