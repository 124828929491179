// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".testimonialsContainer_27yei{background-color:#fff;padding:60px 0 100px;text-align:center}@media only screen and (min-width:600px){.testimonialsContainer_27yei{padding:80px 0 120px}}@media only screen and (min-width:950px){.testimonialsContainer_27yei{padding:80px 0 150px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpointTiny": "350px",
	"breakpointSmall": "450px",
	"breakpointMedium": "600px",
	"breakpointLarge": "800px",
	"breakpointLarger": "950px",
	"breakpointFull": "1150px",
	"breakpointHigh": "1350px",
	"breakpointCinema": "1800px",
	"testimonialsContainer": "testimonialsContainer_27yei"
};
module.exports = ___CSS_LOADER_EXPORT___;
