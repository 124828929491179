<template>
  <div :class="$style.checkListContainer">
    <TransitionOnScroll
      v-for="(category, index) in ['digital', 'economic']"
      :key="`list-${category}`"
      :class="$style.checkList"
      :translateY="[30, 50][isMqLarge ? index : 0]"
      :isIntersecting="isMqLarge && index > 0 ? isIntersecting : undefined"
      v-on="index === 0 ? { intersecting: updateIsIntersecting } : {}"
    >
      <Heading :class="$style.checkListTitle" :tag="HEADINGS.h3">
        {{ $t(`${category}.title`) }}
      </Heading>
      <div v-for="count in 3" :key="`list-item-${count}`" :class="$style.checkListItem">
        <IconCurvyCheck :class="$style.iconCheck" />
        <div :class="$style.checkListItemText">
          {{ $t(`${category}.subtitle${count}`) }}
        </div>
      </div>
    </TransitionOnScroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import IconCurvyCheck from '@/components/_icons/IconCurvyCheck.svg'
import TransitionOnScroll from '@/components/TransitionOnScroll.vue'

export default {
  name: 'HomeCheckList',
  components: {
    Heading,
    IconCurvyCheck,
    TransitionOnScroll,
  },

  data() {
    return {
      HEADINGS,
      isIntersecting: false,
    }
  },

  computed: {
    ...mapGetters({
      isMqLarge: 'viewport/isMqLarge',
    }),
  },

  methods: {
    updateIsIntersecting(isIntersecting) {
      this.isIntersecting = isIntersecting
    },
  },
}
</script>

<style lang="scss" module>
@import '../HomeGreenService/home-green-service-settings';

$container-padding-y: 110px;

.checkListContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: $color-brand-sand-bg-light;
  padding-top: calc(#{$container-padding-y - 30} - (100vw * #{$slant-ratio} / 2));
  padding-bottom: $container-padding-y - 25px;
  padding-left: 40px;
  padding-right: 40px;

  @include min-screen($mq-tiny) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @include min-screen($mq-small) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include text-container-padding-left-larger;

  @include min-screen($mq-larger) {
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-top: calc(#{$container-padding-y} - (100vw * #{$slant-ratio} / 2));
  }
}

.checkList {
  margin: 0;
  padding-top: 30px;

  @include min-screen($mq-small) {
    margin: 0 4vw;
  }

  @include min-screen($mq-larger) {
    margin: 0 7vw 0 0;
    padding-top: 0;
  }
}

.checkListTitle {
  margin-top: 0;
  margin-bottom: 20px;
}

.checkListItem {
  position: relative;

  @include min-screen($mq-small) {
    width: 310px;
  }
}

.iconCheck {
  position: absolute;
  top: 5px;
  left: -6px;
  width: 17px;
}

.checkListItemText {
  margin: 0 0 12px 25px;
}
</style>

<i18n src="@/locales/home-checklist.json" />
