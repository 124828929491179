<template>
  <span>
    <template v-for="(block, index) in contentBlocks">
      <span v-if="block.is === LINE_BREAK_BLOCK_TYPES.nobr" :key="`nobr-${index}`" class="u-nobr"
        >{{ block.value }}<slot name="nobr"
      /></span>
      <template v-else>{{ block.value }} </template>
    </template>
  </span>
</template>

<script>
import { makeHtmlSafe, splitLineBreakOfLastWord, LINE_BREAK_BLOCK_TYPES } from '@/helpers/string'

export default {
  name: 'LineBreakResolverExtra',

  props: {
    text: {
      type: String,
      default: '',
    },

    params: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      LINE_BREAK_BLOCK_TYPES,
    }
  },

  computed: {
    contentBlocks() {
      const sanitizedText = makeHtmlSafe(this.text)
      return splitLineBreakOfLastWord(sanitizedText, this.params)
    },
  },
}
</script>
