// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./home-faq-teaser-man-ipad.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".faqTeaser_23PCG{background-color:#fff}@media only screen and (min-width:800px){.faqTeaser_23PCG{display:flex}}@media only screen and (min-width:800px){.faqTeaserImageWrap_2HoBv,.faqTeaserTextWrap_12_qT{flex:1}}.faqTeaserImageWrap_2HoBv{position:relative;overflow:hidden}.faqTeaserImage_yATG0{display:none;position:absolute;top:0;left:0;width:100%;height:100%}@media only screen and (min-width:800px){.faqTeaserImage_yATG0{display:block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50% 50%;background-repeat:no-repeat;background-size:cover}}.faqTeaserTextWrap_12_qT{background-color:#fff;padding:70px 0;text-align:center;color:#242b59;transition:background-color .3s cubic-bezier(.23,.34,.21,1)}.bgYellow_2FIaS{background-color:#fcde8f}.faqTeaserSubtitle_1f2Ui,.faqTeaserTitle_2vkjF{margin-left:auto;margin-right:auto;padding:0 60px;max-width:580px;box-sizing:content-box}.faqTeaserSubtitle_1f2Ui{margin-bottom:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faqTeaser": "faqTeaser_23PCG",
	"faqTeaserImageWrap": "faqTeaserImageWrap_2HoBv",
	"faqTeaserTextWrap": "faqTeaserTextWrap_12_qT",
	"faqTeaserImage": "faqTeaserImage_yATG0",
	"bgYellow": "bgYellow_2FIaS",
	"faqTeaserSubtitle": "faqTeaserSubtitle_1f2Ui",
	"faqTeaserTitle": "faqTeaserTitle_2vkjF"
};
module.exports = ___CSS_LOADER_EXPORT___;
