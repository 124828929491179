<template>
  <div ref="swiper" class="swiper">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div ref="pagination" class="swiper-pagination"></div>
  </div>
</template>

<script>
import { Swiper, Pagination, Autoplay } from 'swiper'
import { observeIntersection } from '@/helpers/intersection-observer'
import Slide from './Slide.vue'

export { Slide }

const DEFAULT_TRANSITION_SPEED = 600
const AUTOPLAY_DEFAULT_DELAY = 10000

const ACTIVE_SLIDE_CLASS = 'swiper-slide-active'
const ACTIVE_DUPLICATE_SLIDE_CLASS = 'swiper-slide-duplicate-active'

Swiper.use([Pagination, Autoplay])

export default {
  name: 'Carousel',

  props: {
    options: {
      type: Object,
      default: () => ({}),
    },

    loopKey: {
      type: Number,
    },

    isAutoplaying: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeIndex: 0,
      swiper: undefined,
    }
  },

  watch: {
    activeIndex(value) {
      this.$emit('active-index', value)
    },

    loopKey() {
      if (this.swiper) {
        this.swiper.loopDestroy()
        this.swiper.loopCreate()
      }
    },

    isAutoplaying() {
      if (!this.swiper.autoplay) return

      this.isAutoplaying ? this.swiper.autoplay.start() : this.swiper.autoplay.stop()
    },
  },

  mounted() {
    this.initSwiper()
  },

  methods: {
    initSwiper() {
      const that = this
      const options = {
        slidesPerView: 1,
        centeredSlides: true,
        slideToClickedSlide: true,
        loop: true,
        speed: DEFAULT_TRANSITION_SPEED,

        ...this.options,

        pagination: {
          el: this.$refs.pagination,
          type: 'bullets',
          clickable: true,
          renderBullet: (_index, className) => `<span class="${className}"></span>`,
          ...this.options.pagination,
        },

        on: {
          afterInit() {
            // To fix a bug on 3.5 slidesPerView where initially, both the first and last slides
            // have the active class.
            setTimeout(that.fixPossibleDuplicatedActiveClassBugOnInit, 200)
          },

          activeIndexChange() {
            that.$nextTick(() => that.updateActiveIndex())
          },
        },
      }
      if (this.isAutoplaying) {
        options.autoplay = {
          delay: AUTOPLAY_DEFAULT_DELAY,
        }
      }
      this.swiper = new Swiper(this.$refs.swiper, options)

      if (this.isAutoplaying) {
        this.swiper.autoplay.stop()

        observeIntersection(
          this.$refs.swiper,
          () => {
            this.fixPossibleDuplicatedActiveClassBugOnInit()
            this.swiper.autoplay.start()
          },
          { rootMargin: '0%' }
        )
      }
    },

    updateActiveIndex() {
      const selector = `.${ACTIVE_SLIDE_CLASS}, .${ACTIVE_DUPLICATE_SLIDE_CLASS}`
      const activeElement = this.$refs.swiper?.querySelector(selector)
      if (activeElement) {
        this.activeIndex = Number(activeElement.dataset.swiperSlideIndex)
      }
    },

    /**
     * To fix a bug on 3.5 slidesPerView where initially, both the first and last slides
     * have the active class.
     */
    fixPossibleDuplicatedActiveClassBugOnInit() {
      const selector = `.${ACTIVE_DUPLICATE_SLIDE_CLASS}`
      const duplicateActive = this.$refs.swiper?.querySelector(selector)
      duplicateActive?.classList.remove(ACTIVE_DUPLICATE_SLIDE_CLASS)
      this.updateActiveIndex()
    },
  },
}
</script>

<style lang="scss">
@import './swiper';
@import './pagination';
</style>
