// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkListContainer_2OfQN{display:flex;flex-wrap:wrap;justify-content:center;background-color:#f8f5f3;padding:calc(80px - 4.6185vw) 40px 85px}@media only screen and (min-width:350px){.checkListContainer_2OfQN{padding-left:50px;padding-right:50px}}@media only screen and (min-width:450px){.checkListContainer_2OfQN{padding-left:20px;padding-right:20px}}@media only screen and (min-width:950px){.checkListContainer_2OfQN{padding-left:calc(24.9399vw - 170px)}}@media only screen and (min-width:1350px){.checkListContainer_2OfQN{padding-left:calc(24.9399vw - 220px)}}@media only screen and (min-width:950px){.checkListContainer_2OfQN{flex-wrap:nowrap;justify-content:flex-start;padding-top:calc(110px - 4.6185vw)}}.checkList_3c1Ms{margin:0;padding-top:30px}@media only screen and (min-width:450px){.checkList_3c1Ms{margin:0 4vw}}@media only screen and (min-width:950px){.checkList_3c1Ms{margin:0 7vw 0 0;padding-top:0}}.checkListTitle_2q5MJ{margin-top:0;margin-bottom:20px}.checkListItem_vrsNU{position:relative}@media only screen and (min-width:450px){.checkListItem_vrsNU{width:310px}}.iconCheck_vNv81{position:absolute;top:5px;left:-6px;width:17px}.checkListItemText_2G4SM{margin:0 0 12px 25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkListContainer": "checkListContainer_2OfQN",
	"checkList": "checkList_3c1Ms",
	"checkListTitle": "checkListTitle_2q5MJ",
	"checkListItem": "checkListItem_vrsNU",
	"iconCheck": "iconCheck_vNv81",
	"checkListItemText": "checkListItemText_2G4SM"
};
module.exports = ___CSS_LOADER_EXPORT___;
