<template>
  <Slide :class="$style.testimonialSlide">
    <div :class="$style.speechBubbleContentWrap">
      <div :class="$style.speechBubbleWrap">
        <div :class="$style.speechBubble"></div>
        <IconSpeechTriangle :class="$style.speechTriangle" />
      </div>
      <IconQuote :class="$style.quotes" />

      <LineBreakResolver
        :class="[$style.speechBubbleContent, { [$style.fullText]: isShowingFullText }]"
        :text="renderedText"
        :firstLineCharLength="firstLineCharLength"
        :lastNoBrExtraHtml="readMoreHtml"
      />
      <div v-if="translatedFrom" :class="$style.translatedFrom">
        {{ $t(`translatedFrom.${translatedFrom}`) }}
      </div>
    </div>
    <div :class="$style.userInfo">
      <div :class="$style.userImgWrap">
        <img v-if="imgSrc" :class="$style.userImg" :src="imgSrc" />
        <img
          v-else
          :class="$style.userNoImg"
          :src="`https://production-acces-impot-assets.s3.us-east-2.amazonaws.com/testimonial-user-${
            gender === GENDERS.male ? 'm' : 'f'
          }.png`"
        />
      </div>
      <div :class="$style.userFirstName">{{ firstName }}</div>
      <div v-if="location" :class="$style.userLocation">{{ location }}</div>
    </div>
  </Slide>
</template>

<script>
import { mapGetters } from 'vuex'
import { genders as GENDERS } from 'acces-impot-settings-report'
import { findEndOfWordIndex } from '@/helpers/string'
import { Slide } from '@/components/Carousel/Carousel.vue'
import IconQuote from '@/components/_icons/IconQuote.svg'
import IconSpeechTriangle from '@/components/_icons/IconSpeechTriangle.svg'
import LineBreakResolver from '@/components/LineBreakResolver.vue'

export default {
  name: 'HomeTestimonialsSlide',

  components: {
    IconQuote,
    IconSpeechTriangle,
    LineBreakResolver,
    Slide,
  },

  props: {
    text: {
      type: String,
      required: true,
    },

    translatedFrom: {
      type: String,
      default: '',
    },

    firstName: {
      type: String,
      required: true,
    },

    gender: {
      type: String,
      default: '',
    },

    location: {
      type: String,
      default: '',
    },

    imgSrc: {
      type: String,
      default: '',
    },

    readMoreText: {
      type: String,
      required: true,
    },

    truncateAt: {
      type: Number,
      default: 250,
    },

    isReadingMore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      GENDERS,
    }
  },

  computed: {
    ...mapGetters({
      isMqSmall: 'viewport/isMqSmall',
      isMqMedium: 'viewport/isMqMedium',
      isMqLarge: 'viewport/isMqLarge',
    }),

    isShowingFullText() {
      return this.isReadingMore || this.text.length <= this.truncateAt + 20
    },

    firstLineCharLength() {
      return this.isMqLarge ? 25 : this.isMqMedium ? 18 : this.isMqSmall ? 15 : 10
    },

    renderedText() {
      if (this.isShowingFullText) return this.text

      let truncateIndex = findEndOfWordIndex(this.text, this.truncateAt)
      if (/[!?.,;:\-_([]/.test(this.text[truncateIndex - 1])) truncateIndex--

      return `${this.text.substring(0, truncateIndex)}...`
    },

    readMoreHtml() {
      return !this.isShowingFullText
        ? `<span class="${this.$style.readMore}" data-read-more>${this.readMoreText}</span>`
        : ''
    },
  },

  watch: {
    text: 'emitUpdateEvent',
    isShowingFullText: 'emitUpdateEvent',
    firstLineCharLength: 'emitUpdateEvent',
  },

  methods: {
    emitUpdateEvent() {
      this.$nextTick(() => this.$emit('update'))
    },
  },
}
</script>

<style lang="scss" module>
$bubble-transition-translate-y: -30px;
$bubble-transition:
  transform 0.6s $transition-on-scroll-easing,
  opacity 0.6s $transition-on-scroll-easing,
  background-color 0.6s $transition-on-scroll-easing,
  color 0.6s $transition-on-scroll-easing;

.testimonialSlide {
  padding: 50px 0 80px;
}

.speechBubbleContentWrap {
  position: relative;
  margin: 0 20px;
  padding: 50px 40px;
  color: $color-light-grey-3;
  cursor: default;

  @include min-screen($mq-small) {
    padding: 50px;
  }

  :global(.swiper-slide-active) &,
  :global(.swiper-slide-duplicate-active) & {
    color: $color-white;
  }
}

.speechBubbleWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.94);
  transition: $bubble-transition;

  :global(.swiper-slide-active) &,
  :global(.swiper-slide-duplicate-active) & {
    transform: scale(1);
  }
}

.speechBubble {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  border-radius: 6px;
  transition: $bubble-transition;
  z-index: 1;

  :global(.swiper-slide-active) &,
  :global(.swiper-slide-duplicate-active) & {
    background-color: $color-brand-green;
  }
}

.quotes {
  position: absolute;
  top: 22px;
  left: 22px;
  width: 45px;
  color: mix($color-brand-green, $color-white, 20);
  transition: $bubble-transition;
  z-index: 2;

  :global(.swiper-slide-active) &,
  :global(.swiper-slide-duplicate-active) & {
    color: mix($color-brand-green, $color-white, 50);
    transform: translateY(0);
  }
}

.speechTriangle {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 30px;
  color: $color-white;
  transform: translate(-50%, 90%);
  transition: $bubble-transition;
  z-index: 1;

  :global(.swiper-slide-active) &,
  :global(.swiper-slide-duplicate-active) & {
    color: $color-brand-green;
  }
}

.speechBubbleContent {
  display: block;
  position: relative;
  z-index: 5;
}

.readMore {
  display: inline-block;
  position: relative;
  margin-left: 4px;
  text-decoration: underline;
  white-space: nowrap;
  opacity: 0.7;
  transition:
    0.2s opacity,
    0.2s transform;

  :global(.swiper-slide-active) &,
  :global(.swiper-slide-duplicate-active) & {
    cursor: pointer;

    &:hover {
      opacity: 0.9;
      transform: translateX(2px);
    }
  }

  .fullText & {
    display: none;
  }
}

.translatedFrom {
  position: relative;
  padding-top: 20px;
  color: $color-light-grey-3;
  font-size: $font-size-small;
  font-style: italic;
  opacity: 0.8;
  transform: translateY(10px);
  z-index: 5;

  :global(.swiper-slide-active) &,
  :global(.swiper-slide-duplicate-active) & {
    color: $color-white;
  }
}

.userInfo {
  padding-top: 46px;
  color: $color-brand-navy;
  opacity: 0.4;
  transition: $bubble-transition;

  :global(.swiper-slide-active) &,
  :global(.swiper-slide-duplicate-active) & {
    opacity: 1;
  }
}

.userImgWrap {
  overflow: hidden;
  background-color: $color-light-grey-3;
  margin: 0 auto;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.userImg,
.userNoImg {
  width: 100%;
  max-width: 100%;
  vertical-align: top;
}

.userNoImg {
  transform: scale(1.05);
}

.userFirstName {
  margin-top: 20px;
  white-space: nowrap;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 0.09em;
}

.userLocation {
  font-size: $font-size-small;
  letter-spacing: 0.09em;
}
</style>

<i18n src="@/locales/home-testimonials-slide.json" />
