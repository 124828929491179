<template>
  <div :class="$style.slant">
    <slot />
    <div :class="$style.iconContainer">
      <svg :class="$style.icon" viewBox="0 0 996 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1l996 89v2H0V1z" :fill="color" :stroke="color"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slant',

  props: {
    color: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
.slant {
  position: relative;
  overflow: hidden;
}

.iconContainer {
  position: absolute;
  bottom: -1px;
  width: 100%;
}

.icon {
  vertical-align: top;
}
</style>
