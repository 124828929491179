<template>
  <div :class="$style.pricingContainer">
    <div :class="$style.firstHalfBackground"></div>
    <div :class="$style.pricingContainerInner">
      <TransitionOnScroll :class="$style.pricingBubbleWrap" :translateY="0" :scale="0.95">
        <IconPricingBubble :class="$style.pricingBubble" />
      </TransitionOnScroll>
      <TransitionOnScroll
        :class="$style.pricingBubbleManWrap"
        :scale="0.95"
        @intersecting="isBubbleManIntersecting = $event"
      >
        <IconPricingBubbleMan :class="$style.pricingBubbleMan" />
      </TransitionOnScroll>

      <div :class="$style.pricingContent">
        <TransitionOnScroll>
          <Heading :class="$style.pricingTitle" :tag="HEADINGS.h2">{{ $t('title') }}</Heading>
        </TransitionOnScroll>
        <TransitionOnScroll :class="$style.pricingSubtitle">
          <LineBreakResolver :text="$t('subtitle')" />
        </TransitionOnScroll>
        <div :class="$style.pricingTable">
          <TransitionOnScroll
            v-for="(priceItem, index) in prices"
            :key="`price-${index}`"
            :class="$style.pricingItem"
            rootMargin="0px 0px -5% 0px"
            :isIntersecting="isMqMedium && index > 0 ? isPriceItemIntersecting : undefined"
            @intersecting="isPriceItemIntersecting = $event"
          >
            <template v-if="index > 0">
              <span :class="$style.pricingSeparatorLine"></span>
            </template>
            <BeforeHeading :class="$style.pricingItemTitle" :color="BEFORE_HEADING_COLORS.green">
              <LineBreakResolverExtra
                v-if="priceItem.questionMarkCta"
                :text="priceItem.title"
                :params="{ isWrappingLastWordOnly: true }"
              >
                <template #nobr>
                  {{ ' ' }}
                  <span :class="$style.questionMarkCtaWrap">
                    <QuestionMarkCta
                      :isInline="true"
                      :hasNoBackground="true"
                      @click="() => priceItem.questionMarkCta.onClick()"
                    />
                  </span>
                </template>
              </LineBreakResolverExtra>
              <template v-else>
                {{ priceItem.title }}
              </template>
            </BeforeHeading>
            <div :class="$style.pricingItemPrice">{{ getPrice(priceItem.personType) }}</div>
          </TransitionOnScroll>
        </div>
        <TransitionOnScroll
          :class="$style.pricingNote"
          :isIntersecting="isMqLarger ? isBubbleManIntersecting : undefined"
        >
          <component
            :is="isMqLarger ? 'LineLengthEqualizer' : 'LineBreakResolver'"
            :text="$t('child', { price: getPrice(PERSON_TYPES.child) })"
          />
        </TransitionOnScroll>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { PERSON_TYPES } from 'acces-impot-settings-report'
import { PRICES } from 'acces-impot-settings-price'
import { formatPrice } from '@/helpers/locale'
import BeforeHeading, { BEFORE_HEADING_COLORS } from '@/components/Heading/BeforeHeading.vue'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import IconPricingBubble from '@/components/_icons/IconPricingBubble.svg'
import IconPricingBubbleMan from '@/components/_icons/IconPricingBubbleMan.vue'
import LineBreakResolver from '@/components/LineBreakResolver.vue'
import LineBreakResolverExtra from '@/components/LineBreakResolverExtra.vue'
import LineLengthEqualizer from '@/components/LineLengthEqualizer.vue'
import QuestionMarkCta from '@/components/Form/QuestionMarkCta.vue'
import TransitionOnScroll from '@/components/TransitionOnScroll.vue'

export default {
  name: 'HomeCheckList',
  components: {
    BeforeHeading,
    Heading,
    IconPricingBubble,
    IconPricingBubbleMan,
    LineBreakResolver,
    LineBreakResolverExtra,
    LineLengthEqualizer,
    QuestionMarkCta,
    TransitionOnScroll,
  },

  data() {
    return {
      BEFORE_HEADING_COLORS,
      HEADINGS,
      isBubbleManIntersecting: false,
      isPriceItemIntersecting: false,
      PERSON_TYPES,
    }
  },

  computed: {
    ...mapState('page', ['country']),

    ...mapGetters({
      locale: 'page/locale',
      isMqMedium: 'viewport/isMqMedium',
      isMqLarger: 'viewport/isMqLarger',
    }),

    prices() {
      return [
        {
          title: this.$t('adult'),
          personType: PERSON_TYPES.self,
        },
        {
          title: this.$t('partner'),
          personType: PERSON_TYPES.partner,
        },
        {
          title: this.$t('selfEmployed'),
          personType: 'selfEmployed',
          questionMarkCta: {
            onClick: () => {
              this.showOverlayMessage({
                title: this.$t('selfEmployed'),
                body: this.$t('selfEmployedDetails'),
              })
            },
          },
        },
      ]
    },
  },

  watch: {
    isPriceItemIntersecting(value) {
      if (value) this.preloadOverlayMessage()
    },
  },

  methods: {
    ...mapActions({
      preloadOverlayMessage: 'overlay/preloadOverlayMessage',
      showOverlayMessage: 'overlay/showOverlayMessage',
    }),

    getPrice(personType) {
      const price = PRICES[this.country].report[personType]

      // Remove the space before or after the currency symbol
      return formatPrice(price, this.locale, 0).replace(/(.)\s(.)/g, (_, p1, p2) => {
        if ([p1, p2].some(p => /[^\d\w]/.test(p))) return p1 + p2
      })
    },
  },
}
</script>

<style lang="scss" module>
$bubble-ratio: 850 / 1270;
$bubble-width: 1000px;
$bubble-height: round($bubble-width * $bubble-ratio);
$bubble-offset-x: 5px;

$item-space-between: 40px;
$item-width: 26%;

.pricingContainer {
  position: relative;
  background-color: $color-white;

  * {
    box-sizing: content-box;
  }
}

.firstHalfBackground {
  position: absolute;
  background-color: $color-brand-sand-bg-light;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 1;
}

.pricingContainerInner {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100vw;
  max-width: $bubble-width;
  min-height: $bubble-height;
  text-align: center;
  z-index: 2;

  @include min-screen($mq-large) {
    width: calc(100vw - 30px);
  }

  @include min-screen($mq-larger) {
    width: calc(100vw - 60px);
  }
}

.pricingBubbleWrap {
  position: absolute;
  top: 0;
  left: 50%;
  width: 400%;
  height: 100%;
  z-index: 4;

  @include min-screen($mq-small) {
    width: 300%;
  }

  @include min-screen($mq-medium) {
    width: 250%;
  }

  @include min-screen($mq-large) {
    left: $bubble-offset-x;
    width: 100%;
  }
}

.pricingBubble {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  @include min-screen($mq-large) {
    transform: none;
  }
}

.pricingBubbleManWrap {
  position: absolute;
  bottom: 8.5%;
  left: -3.5%;
  width: 220px;
  z-index: 5;

  @include min-screen($mq-medium) {
    bottom: 4%;
    left: -3%;
    width: 250px;
  }

  @include min-screen($mq-large) {
    bottom: 17.8%;
    left: calc(16.5% + #{$bubble-offset-x});
  }
}

.pricingBubbleMan {
  vertical-align: top;

  @include min-screen($mq-large) {
    transform: translate(-50%, 50%);
  }
}

.pricingContent {
  position: relative;
  color: $color-brand-navy;
  z-index: 10;

  // to overwrite Heading margin-top
  .pricingTitle {
    margin-top: 0;
    padding-top: 90px;
  }
}

.pricingTable {
  position: relative;

  @include min-screen($mq-medium) {
    display: flex;
    justify-content: center;
  }
}

.pricingSubtitle {
  margin: 0 auto;
  padding: 0 40px 40px;

  @include min-screen($mq-small) {
    padding: 0 60px 40px;
  }

  @include min-screen($mq-medium) {
    width: 80vw;
    max-width: 600px;
  }
}

.questionMarkCtaWrap {
  position: relative;
  display: inline-block;
  width: calc(1em * #{$line-height-paragraph});
  height: calc(1em * #{$line-height-paragraph});
  vertical-align: top;
}

.pricingItem {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  margin: $item-space-between auto 0;
  padding: 15px 20px 40px;
  width: 180px;

  @include max-screen($mq-small) {
    width: 200px;
  }

  @include max-screen($mq-medium) {
    &:first-child {
      margin-top: 0;
    }
  }

  @include min-screen($mq-medium) {
    margin: 0;
    padding: 12px 0 40px $item-space-between;
    width: $item-width;

    &:first-child {
      padding-left: 0;
    }
  }

  // to overwrite BeforeHeading font-size
  .pricingItemTitle {
    font-size: $font-size-normal;
  }
}

.pricingItemPrice {
  margin-top: 5px;
  white-space: nowrap;
  font-family: $font-family-heading;
  font-weight: bold;
  font-size: 42px;

  @include min-screen($mq-large) {
    font-size: 48px;
  }
}

.pricingSeparatorLine {
  position: absolute;
  background-color: $color-light-grey-1;
  top: -40px;
  width: calc(100% - 30px);
  height: 1px;
  opacity: 0.5;

  @include min-screen($mq-medium) {
    top: 0;
    left: round($item-space-between / 2);
    width: 1px;
    height: 100%;
  }

  @include max-screen($mq-medium) {
    left: 15px;
  }
}

.pricingNote {
  margin: 0 auto;
  padding: 30px 30px 340px;
  transform: translateX(1%);

  @include min-screen($mq-tiny) {
    padding: 30px 60px 320px;
  }

  @include min-screen($mq-small) {
    padding: 30px 100px 280px;
  }

  @include min-screen($mq-medium) {
    padding: 30px 120px 200px;
  }

  @include min-screen($mq-large) {
    padding: 40px 150px 150px;
    width: 560px;
  }

  @include min-screen($mq-larger) {
    padding: 40px 0 150px;
    transform: none;
  }
}
</style>

<i18n src="@/locales/home-pricing.json" />
