// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

/**
 * For the most common use of IntersectionObserver which is lazy loading elements once. For more
 * complex use of IntersectionObserver, you should use createIntersectionObserver.
 *
 * @name observeIntersection
 * @function
 * @param {HTMLElement} element
 * @param {Function} callback
 * @param {Object} opts
 */
export function observeIntersection(element, callback, opts = {}) {
  const defaults = {
    rootMargin: '100%', // IntersectionObserver option
    once: true,
  }
  const options = { ...defaults, ...opts }

  const handler = (entry, observer) => {
    if (!entry || entry.isIntersecting) {
      callback(entry)
      if (observer && options.once) observer.disconnect()
    }
  }
  createIntersectionObserver(element, handler, opts)
}

/**
 * IntersectionObserver wrapper
 *
 * @name createIntersectionObserver
 * @function
 * @param {HTMLElement} element
 * @param {Function} callback
 * @param {Object} opts
 */
export function createIntersectionObserver(element, callback, opts = {}) {
  if (!window.IntersectionObserver) return callback()

  const handler = (entries, observer) => {
    entries.forEach(entry => callback(entry, observer))
  }
  return new IntersectionObserver(handler, opts).observe(element)
}
