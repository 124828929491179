<template>
  <div :class="[$style.beforeHeading, $style[color]]">
    <slot />
  </div>
</template>

<script>
export const BEFORE_HEADING_COLORS = {
  yellow: 'yellow',
  green: 'green',
}

export default {
  name: 'BeforeHeading',

  props: {
    color: {
      type: String,
      default: BEFORE_HEADING_COLORS.yellow,
    },
  },
}
</script>

<style lang="scss" module>
@import './heading-settings';

.beforeHeading {
  @include h4-styles;
}

.yellow {
  color: $color-brand-yellow;
}

.green {
  color: $color-brand-green;
}
</style>
