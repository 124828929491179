import countrySettings from 'acces-impot-settings-country'

export function formatNumber(number, locale) {
  return new Intl.NumberFormat(locale).format(number)
}

export function formatPrice(number, locale, minimumFractionDigits = 2) {
  const country = locale.match(/[A-Za-z]{2}$/)[0]
  const currency = countrySettings[country].currency.toUpperCase()

  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
  }).format(number)

  return replaceDollar(formattedPrice, ' $ ')
}

export function formatPercent(number, locale, opts) {
  const defaultOptions = { minimumFractionDigits: 0, maximumFractionDigits: 5 }
  const options = { ...defaultOptions, ...opts }

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    ...options,
  }).format(number)
}

export function replaceDollar(string, replacement) {
  return string.replace(/[A-Za-z\s]*\$[A-Za-z\s]*/g, replacement)
}
