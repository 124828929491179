// Generated with https://csswarp.eleqtriq.com/
//
// font: Urbanist 600
// size: 14
// kerning: 2
// line-height: 0.65
// radius: 46
// x: 64
// y: 64

export const PLAY_LABEL_CHAR = {
  en: {
    label: 'HOW IT WORKS',
    styles: [
      {
        radius: -1.15,
        width: 9,
        height: 9,
        top: 35.95,
        left: 17.62,
      },
      {
        radius: -0.89,
        width: 11,
        height: 9,
        top: 25.8,
        left: 22.95,
      },
      {
        radius: -0.58,
        width: 13,
        height: 9,
        top: 16.49,
        left: 32.33,
      },
      {
        radius: -0.37,
        width: 3,
        height: 9,
        top: 11.97,
        left: 46.23,
      },
      {
        radius: -0.25,
        width: 4,
        height: 9,
        top: 10.33,
        left: 50.98,
      },
      {
        radius: -0.06,
        width: 9,
        height: 9,
        top: 9.07,
        left: 56.87,
      },
      {
        radius: 0.11,
        width: 3,
        height: 9,
        top: 9.31,
        left: 67.86,
      },
      {
        radius: 0.33,
        width: 13,
        height: 9,
        top: 11.54,
        left: 72.59,
      },
      {
        radius: 0.63,
        width: 11,
        height: 9,
        top: 18.06,
        left: 85.92,
      },
      {
        radius: 0.88,
        width: 8,
        height: 9,
        top: 25.99,
        left: 95.7,
      },
      {
        radius: 1.1,
        width: 8,
        height: 9,
        top: 34.38,
        left: 101.12,
      },
      {
        radius: 1.33,
        width: 9,
        height: 9,
        top: 44.22,
        left: 104.21,
      },
    ],
  },
  fr: {
    label: 'COMMENT ÇA FONCTIONNE',
    styles: [
      {
        radius: 4.68,
        width: 10,
        height: 9,
        top: 56.24,
        left: 13.01,
      },
      {
        radius: 4.95,
        width: 11,
        height: 9,
        top: 43.85,
        left: 13.87,
      },
      {
        radius: 5.26,
        width: 13,
        height: 9,
        top: 30.99,
        left: 18.26,
      },
      {
        radius: 5.58,
        width: 13,
        height: 9,
        top: 19.68,
        left: 28.01,
      },
      {
        radius: 5.85,
        width: 8,
        height: 9,
        top: 13.07,
        left: 41.07,
      },
      {
        radius: 6.08,
        width: 9,
        height: 9,
        top: 9.87,
        left: 50.55,
      },
      {
        radius: 6.32,
        width: 9,
        height: 9,
        top: 9.04,
        left: 61.49,
      },
      {
        radius: 6.5,
        width: 3,
        height: 9,
        top: 10.08,
        left: 72.42,
      },
      {
        radius: 6.68,
        width: 10,
        height: 9,
        top: 12.67,
        left: 77,
      },
      {
        radius: 6.94,
        width: 10,
        height: 9,
        top: 18.74,
        left: 87.31,
      },
      {
        radius: 7.13,
        width: 3,
        height: 9,
        top: 24.56,
        left: 96.99,
      },
      {
        radius: 7.28,
        width: 7,
        height: 9,
        top: 30.14,
        left: 99.2,
      },
      {
        radius: 7.52,
        width: 11,
        height: 9,
        top: 40.02,
        left: 101.99,
      },
      {
        radius: 7.78,
        width: 9,
        height: 9,
        top: 51.74,
        left: 105.38,
      },
      {
        radius: 8.03,
        width: 10,
        height: 9,
        top: 63.19,
        left: 104.26,
      },
      {
        radius: 8.28,
        width: 9,
        height: 9,
        top: 74.14,
        left: 101.32,
      },
      {
        radius: 8.46,
        width: 4,
        height: 9,
        top: 81.5,
        left: 99.59,
      },
      {
        radius: 8.67,
        width: 11,
        height: 9,
        top: 88.64,
        left: 89.86,
      },
      {
        radius: 8.93,
        width: 9,
        height: 9,
        top: 95.59,
        left: 81.12,
      },
      {
        radius: 9.17,
        width: 9,
        height: 9,
        top: 99.56,
        left: 70.89,
      },
      {
        radius: 9.4,
        width: 8,
        height: 9,
        top: 100.98,
        left: 61.01,
      },
    ],
  },
}
