import { genders as GENDERS } from 'acces-impot-settings-report'

export const testimonials = [
  {
    firstName: 'Géraldine',
    gender: GENDERS.female,
    location: 'Longue-Pointe-de-Mingan, QC',
    imgSrc:
      'https://production-acces-impot-assets.s3.us-east-2.amazonaws.com/testimonial-user-1.jpg',
    originalText: {
      fr: 'Accès Impôt m’a permis de faire mes impôts dans le confort de mon salon avec des employés compétents qui ont répondus à toutes mes questions rapidement. Ils sont efficaces pour la production des rapports et ont trouvés toutes les déductions auxquelles j’avais droit. Cette année avec une nouvelle maison, un nouveau bébé et une situation familiale changée leur aide sera grandement appréciée ! Aussi leur site est vraiment facile et convivial pour les documents, parfait pour les technos nuls comme moi !',
    },
    translatedText: {
      en: 'Accès Impôt allowed me to do my taxes in the comfort of my living room with competent employees who answered all my questions quickly. They are efficient at filing and found all the deductions I was entitled to. This year with a new house, a new baby and a changed family situation their help will be greatly appreciated! Also their site is really easy and user friendly for documents, perfect for tech noobs like me!',
    },
  },
  {
    firstName: 'Florence',
    gender: GENDERS.female,
    location: 'Québec, QC',
    imgSrc:
      'https://production-acces-impot-assets.s3.us-east-2.amazonaws.com/testimonial-user-2.jpg',
    originalText: {
      fr: 'Cela va faire maintenant 2 ans que j’utilise les services d’Accès Impôt, et j’ai pratiquement hâte à la période d’impôts 2021! Le service est très facile d’utilisation, clair et précis , mes questions sont toujours les bienvenus et il est aussi très facile de modifier ou ajouter un document après avoir soumis notre dossier. Ils sont vraiment là pour faciliter cette période, et font en sorte, dans la mesure du possible, qu’on en retire le plus d’avantages! Ils sont efficaces et tellement serviables! J’adore leurs services et je ne ferais affaire avec personne d’autre qu’eux pour mes impôts.',
    },
    translatedText: {
      en: "I've been using Accès Impôt for 2 years now, and I can hardly wait for the 2021 tax season! The service is very easy to use, clear and precise, my questions are always welcome and it is also very easy to modify or add a document after submitting our file. They are really there to facilitate this period, and make sure, as much as possible, that we get the most out of it! They are efficient and so helpful! I love their services and would not do my taxes with anyone else.",
    },
  },
  {
    firstName: 'Cindy',
    gender: GENDERS.female,
    location: 'Baie-Comeau, QC',
    imgSrc:
      'https://production-acces-impot-assets.s3.us-east-2.amazonaws.com/testimonial-user-3.jpg',
    originalText: {
      fr: "À première vu, je trouvais trop simple pour que ce soit vrai, mais je me suis lancé après avoir vu les commentaires des autres. Finalement j'ai trouvé une entreprise proche du client, qui répond à sa clientèle de façon, clair, précise et avenante. Le service à été impeccable, nous avons même fait recalculer des années antérieurs, ce qui nous a permis de récupérer des milliers de dollars. L'équipe est très professionnel, rapide et efficace. Je recommande à n'importe qui de faire affaire avec Accès Impôt qui nous a enlevé un très gros poids de sur les épaules. La disponibilité et la rapidité du personnel en retour des questionnements et du traitement des dossiers à aussi été surprenantes, mais la facilité de transmettre, de signer de traiter les demandes au tout aussi efficace. Un gros merci et bonne continuité, pour ma part nous serons clients, tant et aussi longtemps que votre compagnie existera.",
    },
    translatedText: {
      en: 'At first glance, I thought it was too simple to be true, but I took the plunge after seeing the comments of others. Finally I found a company that is close to the customer, that responds to its customers in a clear, precise and friendly way. The service was impeccable, we even had previous years recalculated, which allowed us to recover thousands of dollars. The team is very professional, fast and efficient. I would recommend anyone to do business with Accès Impôt who took a big weight off our shoulders. The availability and speed of the staff in response to questions and the processing of files was also surprising, but the ease of transmitting, signing and processing requests was just as effective. A big thank you and good continuity, for my part we will be customers, as long as your company exists.',
    },
  },
  {
    firstName: 'Andrée-Anne',
    gender: GENDERS.female,
    location: 'Sainte-Julienne, QC',
    imgSrc:
      'https://production-acces-impot-assets.s3.us-east-2.amazonaws.com/testimonial-user-4.jpg',
    originalText: {
      fr: "J'ai adoré mon expérience avec Accès Impôt! Bien que je ne sois pas très à l'aise avec l'informatique, j'ai trouvé la plate-forme très facile d'utilisation que ce soit avec mon ordinateur ou mon cellulaire. Lorsque j'ai eu des questionnements ou besoin d'ajouter des documents à mon dossier, j'ai eu un retour très rapidement autant par courriel que par téléphone. J'ai été bien accompagnée et bien informé. Ma déclaration de revenus a été produite et transmise plus rapidement que lorsque j'ai fait affaire avec d'autres entreprises reconnues. J'ai été si emballée par mon expérience que j'ai parlé de ce service et l'ai chaudement recommandé à mon entourage. Je n'en revenais tout simplement pas de la facilité avec laquelle la démarche s'était déroulée et je n'avais aucun points négatifs à mentionner.",
    },
    translatedText: {
      en: 'I loved my experience with Accès Impôt! Although I am not very comfortable with computers, I found the platform very easy to use, whether it was with my computer or my cell phone. When I had questions or needed to add documents to my file, I got a quick response both by email and by phone. I was well accompanied and well informed. My tax return was filed and transmitted more quickly than when I dealt with other reputable companies. I was so thrilled with my experience that I have talked about this service and highly recommended it to others. I simply could not believe how easy the process was and I had no negative points to mention.',
    },
  },
  {
    firstName: 'Fannie-Maude',
    gender: GENDERS.female,
    location: 'Montréal, QC',
    imgSrc:
      'https://production-acces-impot-assets.s3.us-east-2.amazonaws.com/testimonial-user-5.jpg',
    originalText: {
      fr: 'Un service abordable, rapide, simple et efficace! Ils se sont montrés disponible pour répondre à toute mes questions. Je suis très satisfaite du service et le recommande grandement!',
    },
    translatedText: {
      en: 'An affordable, fast, simple and efficient service! They were available to answer all my questions. I am very satisfied with the service and highly recommend it!',
    },
  },
]
