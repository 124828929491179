<template>
  <component
    :is="tag"
    ref="container"
    :class="isVisible ? $style.visible : $style.hidden"
    :style="elementStyles"
  >
    <slot />
  </component>
</template>

<script>
import { createIntersectionObserver } from '@/helpers/intersection-observer'

export const DEFAULT_TRANSLATE_Y = 30

export default {
  name: 'TransitionOnScroll',

  props: {
    tag: {
      type: String,
      default: 'div',
    },

    /**
     * If defined, the visibility is controlled via this prop and no intersection observer will be set
     */
    isIntersecting: {
      type: Boolean,
      default: undefined,
    },

    translateY: {
      type: Number,
      default: DEFAULT_TRANSLATE_Y,
    },

    scale: {
      type: Number,
      default: 1,
    },

    /**
     * If defined, it will overwrite `translateY` and `scale` props
     */
    transformStart: {
      type: String,
    },

    transformEnd: {
      type: String,
    },

    isAnimatingOnce: {
      type: Boolean,
      default: true,
    },

    rootMargin: {
      type: String,
      default: '0px 0px -10% 0px',
    },

    delayBeforeEnter: {
      type: Number,
      default: 0,
    },

    delayBeforeLeave: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      observer: undefined,
      isVisible: false,
      timeoutBeforeEnter: null,
      timeoutBeforeLeave: null,
    }
  },

  computed: {
    elementStyles() {
      return this.isVisible ? this.transformEnd : this.hiddenStyles
    },

    hiddenStyles() {
      if (this.transformStart) return { transform: this.transformStart }

      const transforms = []
      if (this.translateY) transforms.push(`translateY(${this.translateY}px)`)
      if (this.scale !== 1) transforms.push(`scale(${this.scale})`)
      const transform = transforms.join(' ')

      return transform ? { transform } : {}
    },
  },

  watch: {
    isVisible: {
      immediate: true,
      handler() {
        this.$emit('intersecting', this.isVisible)
      },
    },

    isIntersecting: {
      immediate: true,
      handler() {
        if (this.isIntersecting === undefined) {
          if (this.$refs?.container) this.addIntersectionObserver()
          return
        }
        this.observer?.disconnect()
        this.isVisible = this.isIntersecting
      },
    },
  },

  mounted() {
    if (this.isIntersecting === undefined) this.addIntersectionObserver()
  },

  methods: {
    addIntersectionObserver() {
      if (!window.IntersectionObserver) return setTimeout(() => (this.isVisible = true), 100)
      if (this.observer) return

      const container = this.$refs.container?.$el || this.$refs.container
      const handler = (entry, observer) => {
        if (!entry) return

        const setIsIntersecting = () => {
          this.isVisible = entry.isIntersecting
          if (this.isVisible && this.isAnimatingOnce) {
            observer.disconnect()
            this.observer = undefined
          }
        }
        let delay = this.delayBeforeEnter
        let timeout = this.timeoutBeforeEnter

        if (!entry.isIntersecting) {
          delay = this.delayBeforeLeave
          timeout = this.timeoutBeforeLeave
        }
        clearTimeout(timeout)
        delay ? (timeout = setTimeout(setIsIntersecting, delay)) : setIsIntersecting()
      }
      this.observer = createIntersectionObserver(container, handler, {
        rootMargin: this.rootMargin,
      })
    },
  },
}
</script>

<style lang="scss" module>
$easing: $transition-on-scroll-easing;

:export {
  easing: $easing;
}

.hidden {
  opacity: 0;
  transition:
    transform 0.3s $easing,
    opacity 0.3s $easing;
}

.visible {
  transition-duration: 0.8s;
  transition-timing-function: $easing;
}
</style>
