<template>
  <span>
    <template v-for="(block, index) in contentBlocks">
      <span v-if="block.is === LINE_BREAK_BLOCK_TYPES.nobr" :key="`nobr-${index}`" class="u-nobr">{{
        block.value
      }}</span>
      <template v-else>{{ block.value }} </template>
    </template>
  </span>
</template>

<script>
import { LINE_BREAK_BLOCK_TYPES } from '@/helpers/string'

export default {
  name: 'LineLengthEqualizer',

  props: {
    isActive: {
      type: Boolean,
      default: true,
    },

    text: {
      type: String,
      default: '',
    },

    firstLineRatio: {
      type: Number,
      default: 0.55,
      validator: num => num >= 0 && num <= 1,
    },
  },

  data() {
    return {
      LINE_BREAK_BLOCK_TYPES,
    }
  },

  computed: {
    contentBlocks() {
      return this.isActive && this.text
        ? this.equalizeLineLengthOnBreak(this.text)
        : [{ is: LINE_BREAK_BLOCK_TYPES.text, value: this.text }]
    },
  },

  methods: {
    equalizeLineLengthOnBreak(sentence) {
      const halfLength = Math.round(sentence.length * this.firstLineRatio)
      const halfIndex = halfLength + sentence.substring(halfLength).match(/( +|$)/).index
      const firstLine = sentence.substring(0, halfIndex)
      const secondLine = sentence.substring(halfIndex)

      return [
        { is: LINE_BREAK_BLOCK_TYPES.text, value: firstLine },
        { is: LINE_BREAK_BLOCK_TYPES.nobr, value: secondLine },
      ]
    },
  },
}
</script>
