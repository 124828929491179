<template>
  <div :class="$style.faqTeaser">
    <div :class="$style.faqTeaserImageWrap">
      <TransitionOnScroll
        :class="$style.faqTeaserImage"
        :translateY="0"
        :scale="1.05"
        v-on="isMqLarge ? { intersecting: updateIsIntersecting } : {}"
      />
    </div>
    <div :class="[$style.faqTeaserTextWrap, { [$style.bgYellow]: isIntersecting }]">
      <TransitionOnScroll
        :translateY="30"
        :rootMargin="isMqLarge ? '0px 0px -10% 0px' : '0px 0px -5% 0px'"
        :isIntersecting="isMqLarge ? isIntersecting : undefined"
        v-on="isMqLarge ? {} : { intersecting: updateIsIntersecting }"
      >
        <Heading :class="$style.faqTeaserTitle" :tag="HEADINGS.h3">
          <LineBreakResolver :text="$t('faqTeaser.title')" />
        </Heading>
      </TransitionOnScroll>
      <TransitionOnScroll
        :class="$style.faqTeaserSubtitle"
        :translateY="50"
        :isIntersecting="isIntersecting"
      >
        <LineBreakResolver :text="$t('faqTeaser.subtitle')" />
      </TransitionOnScroll>
      <TransitionOnScroll
        :class="$style.buttonWrap"
        :translateY="80"
        :isIntersecting="isIntersecting"
      >
        <Button
          :class="$style.button"
          :shape="BUTTON_STYLES.shape.spade"
          :to="localePath(ROUTE_NAMES.faq.index)"
        >
          {{ $t('faqTeaser.goToFaq') }}
        </Button>
      </TransitionOnScroll>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ROUTE_NAMES } from '@/constants/route-names'
import Button, { BUTTON_STYLES } from '@/components/Form/Button.vue'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import LineBreakResolver from '@/components/LineBreakResolver.vue'
import TransitionOnScroll from '@/components/TransitionOnScroll.vue'

export default {
  name: 'HomeFaqTeaser',
  components: {
    Button,
    Heading,
    LineBreakResolver,
    TransitionOnScroll,
  },

  data() {
    return {
      BUTTON_STYLES,
      HEADINGS,
      isIntersecting: false,
      ROUTE_NAMES,
    }
  },

  computed: {
    ...mapGetters({
      isMqLarge: 'viewport/isMqLarge',
    }),
  },

  methods: {
    updateIsIntersecting(isIntersecting) {
      this.isIntersecting = isIntersecting
    },
  },
}
</script>

<style lang="scss" module>
.faqTeaser {
  background-color: $color-white;

  @include min-screen($mq-large) {
    display: flex;
  }
}

.faqTeaserImageWrap,
.faqTeaserTextWrap {
  @include min-screen($mq-large) {
    flex: 1;
  }
}

.faqTeaserImageWrap {
  position: relative;
  overflow: hidden;
}

.faqTeaserImage {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include min-screen($mq-large) {
    display: block;
    background-image: url('./home-faq-teaser-man-ipad.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.faqTeaserTextWrap {
  background-color: $color-white;
  padding: 70px 0;
  text-align: center;
  color: $color-brand-navy;
  transition: background-color 0.3s $transition-on-scroll-easing;
}

.bgYellow {
  background-color: $color-brand-yellow-light;
}

.faqTeaserTitle,
.faqTeaserSubtitle {
  margin-left: auto;
  margin-right: auto;
  padding: 0 60px;
  max-width: 580px;
  box-sizing: content-box;
}

.faqTeaserSubtitle {
  margin-bottom: 40px;
}
</style>

<i18n src="@/locales/home-faq-teaser.json" />
